import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { StructuredText } from 'react-datocms'
import { GatsbyImage } from "gatsby-plugin-image"
import { Fade } from "react-reveal"
import Seo from "../components/seo"

const Biographie = ({data}) => {
  
  return (
  <Layout>
      <Seo meta={data.bio.seoMetaTags} />
      <h1 className="breadcrumb">Biographie</h1>
      <div className="container maxwidth-800 justify">
      <Fade up duration={800} distance="50px">
      {
        <StructuredText
          data={data.bio.biographie}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsArticle":
                return <a href={`/articles/${record.slug}`}>{record.title}</a>;
              default:
                return null;
            }
          }}
          renderLinkToRecord={({ record, children }) => {
            switch (record.__typename) {
              case "DatoCmsArticle":
                return <a href={`/articles/${record.slug}`}>{children}</a>;
              default:
                return null;
            }
          }}
         
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsImageBlock":
                return  <div  className="illustration"><GatsbyImage image={record.image.gatsbyImageData} alt={record.image.alt}/><span>{record.image.title}</span></div>
                ;
              default:
                return  null;
            }
          }}
          
        />
      }
      </Fade>
      </div>
  </Layout>
  )
}

export default Biographie


export const query = graphql`
  query BiographyQuery {
    bio:datoCmsBiographie {
      seoMetaTags  {
        ...GatsbyDatoCmsSeoMetaTags
      }
      biographie {
        value
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              gatsbyImageData
              title
              alt
              
            }
          }
        }
      }
  }
}
`
